.page-content ul:not(.pagination) {
  list-style: none;
  padding-left: 0;
  margin: 3rem 0;

  li {
    background-color: rgba($dark, 10%);
    color: $dark;
    font-weight: $font-weight-bold;
    font-size: 1rem;
    margin: 15px 0;
    padding: 16px 25px;
    border-radius: 0 20px 0 20px;
  }
}
