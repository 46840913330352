:root {
  --arrow: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%226%22%20fill%3D%22%23D8B56D%22%3E%3Cpath%20d%3D%22M1.175%200%205%203.709%208.825%200%2010%201.14%205%206%200%201.14z%22%2F%3E%3C%2Fsvg%3E");
}

body {
  background-color: #f4f3f8;
}

h6, h5, h4, h3, h2, h1 {
  color: #3a3286;
  font-weight: 700;
}

.page-content {
  margin-bottom: 3.5rem;
}

.page-content h2, .page-content h3 {
  margin-top: 1.25em;
  margin-bottom: .5em;
}

.page-content img {
  max-width: 100%;
}

.list-unstyled, .pagination {
  padding-left: 0;
  list-style: none;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.zindex-fix {
  z-index: 1;
  position: relative;
}

.img-cover {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.rounded-img {
  max-width: 140px;
  border-radius: 0 40px 0 80px;
}

.map {
  min-height: 400px;
  border-top-right-radius: 200px;
}

.hover-text-primary:hover {
  color: #0d0939;
}

.date, .last-posts-date {
  width: 122px;
  color: #fff;
  text-align: center;
  background-color: #d8b56d;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-weight: 600;
  line-height: 1.1;
  display: flex;
}

.site-btn, .site-btn-2 {
  color: #fff;
  text-align: center;
  background-color: #3a3286;
  border: none;
  border-radius: 0 20px 0 40px;
  padding: 1rem 4rem;
  font-weight: 600;
  transition: background-color .3s;
  display: inline-block;
  position: relative;
}

.site-btn:before, .site-btn-2:before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #0d0939;
  background-image: var(--arrow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%)rotate(-90deg);
}

.site-btn:hover, .site-btn-2:hover, .site-btn:focus, .site-btn-2:focus {
  color: #fff;
  background-color: #0d0939;
}

.site-btn-2 {
  color: #3a3286;
  background-color: #d8b56d;
}

blockquote {
  color: #3a3286;
  text-align: center;
  background: linear-gradient(90deg, #d8b56d 0%, #d8b56d80 100%);
  border-radius: 0 20px 0 40px;
  margin: 6rem 0 3rem;
  padding: 50px 15px 20px;
  font-size: calc(1.275rem + .3vw);
  font-weight: 600;
  position: relative;
}

blockquote:before {
  content: "";
  width: 60px;
  height: 60px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2246%22%20height%3D%2230%22%20fill%3D%22%233a3286%22%3E%3Cpath%20d%3D%22M35.57%200a10.636%2010.636%200%200%200-10.555%2010.8%2010.794%2010.794%200%200%200%2010.742%2010.8c.059%200%20.148-.008.207-.009a13.436%2013.436%200%200%201-8.467%203.009%202.678%202.678%200%200%200-2.662%202.7%202.488%202.488%200%200%200%202.424%202.7A18.91%2018.91%200%200%200%2046%2010.8v-.007A10.529%2010.529%200%200%200%2035.57%200zM10.75%200A10.643%2010.643%200%200%200%20.187%2010.8a10.79%2010.79%200%200%200%2010.739%2010.8c.059%200%20.146-.008.2-.009A13.439%2013.439%200%200%201%202.658%2024.6%202.679%202.679%200%200%200%200%2027.3%202.5%202.5%200%200%200%202.439%2030a18.923%2018.923%200%200%200%2018.756-19.2v-.007A10.542%2010.542%200%200%200%2010.75%200z%22%2F%3E%3C%2Fsvg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-content ol:not(.pagination) {
  counter-reset: licznik;
  margin: 3rem 0;
  padding-left: 0;
  list-style: none;
}

.page-content ol:not(.pagination) li {
  color: #3a3286;
  min-height: 45px;
  align-items: center;
  margin: 15px 0;
  padding-left: 60px;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  position: relative;
}

.page-content ol:not(.pagination) li:before {
  counter-increment: licznik;
  content: counter(licznik);
  width: 45px;
  height: 45px;
  background-color: #3a32861a;
  border-radius: 0 20px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.page-content ul:not(.pagination) {
  margin: 3rem 0;
  padding-left: 0;
  list-style: none;
}

.page-content ul:not(.pagination) li {
  color: #3a3286;
  background-color: #3a32861a;
  border-radius: 0 20px;
  margin: 15px 0;
  padding: 16px 25px;
  font-size: 1rem;
  font-weight: 600;
}

table {
  max-width: 100%;
}

table td, table th {
  min-width: 150px;
  border: 2px solid #3a32861a;
  padding: 15px;
}

table th {
  color: #0d0939;
  text-transform: uppercase;
  text-align: center;
  background-color: #3a32861a;
  font-weight: 500;
}

table td {
  background-color: #fff;
}

.table-responsive {
  scrollbar-color: #d8b56d #3a32861a;
  scrollbar-width: thin;
  margin: 3rem 0;
  padding-bottom: 1rem;
  overflow-x: auto;
}

.table-responsive::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #d8b56d;
  border-radius: 5px;
}

.table-responsive::-webkit-scrollbar-track {
  background: #3a32861a;
  border-radius: 5px;
}

.pagination {
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination-arrow--prev, .pagination-arrow--next {
  position: relative;
  border-radius: 0 20px 0 35px !important;
}

.pagination-arrow--prev:before, .pagination-arrow--next:before {
  content: "";
  background-image: var(--arrow);
  background-position: 50% 60%;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  position: absolute;
  inset: 0;
  transform: rotate(-90deg);
}

.pagination-arrow--prev:hover:before, .pagination-arrow--next:hover:before {
  filter: brightness(0%) invert(24%) sepia(11%) saturate(4754%) hue-rotate(214deg) brightness(92%) contrast(103%);
}

.pagination-arrow--prev {
  transform: rotate(180deg)scaleY(-1);
  margin-left: 0 !important;
  margin-right: 20px !important;
}

.pagination-arrow--next {
  margin-left: 20px !important;
  margin-right: 0 !important;
}

.pagination a {
  min-width: 45px;
  min-height: 45px;
  color: #fff;
  background-color: #3a3286;
  border-radius: 0 20px;
  justify-content: center;
  align-items: center;
  margin: 5px;
  transition: background-color .3s;
  display: flex;
}

.pagination a:hover, .pagination .active {
  background-color: #d8b56d;
}

.header-nav a {
  color: #fff;
  font-weight: 600;
}

.header-nav a:hover, .header-nav a.active {
  color: #d8b56d;
}

.select-lang {
  position: relative;
}

.select-lang:after {
  content: "";
  width: 20px;
  height: 20px;
  color: #d8b56d;
  background-color: #0d0939;
  background-image: var(--arrow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
}

.select-lang select {
  height: 60px;
  width: 60px;
  color: #fff;
  text-align: center;
  appearance: none;
  cursor: pointer;
  background-color: #3a3286;
  border: 0;
  border-radius: 0 33% 0 50%;
}

.nav-toggler {
  height: 100%;
  width: 100%;
  background-color: #0000;
  border: 1px solid #0000;
  padding: 0;
}

.nav-toggler-icon {
  vertical-align: middle;
  width: 60px;
  height: 3px;
  border-radius: 15px;
  margin-bottom: 3px;
  transition: background-color .1s;
  display: inline-block;
  position: relative;
}

.nav-toggler-icon:before, .nav-toggler-icon:after {
  content: "";
  width: 40px;
  height: 4px;
  background-color: #d8b56d;
  transition: transform .3s, width .3s;
  position: absolute;
  top: -15px;
  left: 0;
  transform: rotate(45deg)translate(15px, 5px);
}

.nav-toggler-icon:after {
  top: 15px;
  transform: rotate(-45deg)translate(15px, -5px);
}

.nav-toggler.collapsed .nav-toggler-icon {
  background-color: #d8b56d;
}

.nav-toggler.collapsed .nav-toggler-icon:before, .nav-toggler.collapsed .nav-toggler-icon:after {
  width: 60px;
  height: 3px;
  border-radius: 15px;
  transform: none;
}

.nav-toggler.collapsed .nav-toggler-icon:after {
  width: 40px;
}

#navbarNav {
  font-size: max(16px, 1rem);
}

.contact-banner {
  background-color: #0000;
  background-image: linear-gradient(90deg, #d8b56d 0%, #d8b56d80 100%), url("https://picsum.photos/800/450");
  background-position: 50%;
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-radius: 0 50px 0 80px;
}

.last-posts {
  position: relative;
}

.last-posts-grid {
  grid-template: "header"
                 "slider"
                 "btn"
                 / 100%;
  display: grid;
}

.last-posts-slider {
  grid-area: slider;
}

.last-posts-header {
  grid-area: header;
}

.last-posts-btn {
  grid-area: btn;
}

.last-posts-date {
  margin-left: .75rem;
  font-size: 20px;
  position: absolute;
  top: 0;
  left: 1.5rem;
  height: 100px !important;
  width: 100px !important;
}

.last-posts-slide {
  margin-top: 50px;
}

.last-posts-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.last-posts-link:hover h3 {
  -webkit-text-decoration: underline #d8b56d;
  text-decoration: underline #d8b56d;
}

.header-slide {
  text-align: center;
  padding: max(15%, 60px) .75rem;
  font-size: clamp(28px, 1rem + 2.5vw, 60px);
  position: relative;
}

.header-img {
  filter: brightness(.5);
  background-color: #3a3286;
}

.header-section {
  position: relative;
}

.header-section:before {
  content: "";
  width: 75%;
  height: 75%;
  background-color: #fff;
  border-bottom-left-radius: 5rem;
  position: absolute;
  top: 0;
  left: 0;
}

.header .header-slide a {
  color: #fff;
  transition: all .5s;
}

.header .header-slide a:hover {
  color: #d8b56d;
}

.offer-title {
  font-size: 1.25rem;
}

.offer-card {
  background-color: #3a3286;
  overflow: hidden;
}

.offer-inner {
  z-index: 1;
  background-color: #0d0939cc;
  transition: top .6s;
  position: absolute;
  inset: auto 20px 20px;
}

.offer-extended-content {
  opacity: 0;
  display: none;
}

.before-decoration {
  position: relative;
}

.before-decoration:before {
  content: "";
  height: 250px;
  background-color: #0d0939;
  border-bottom-left-radius: 200px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.about-us {
  grid-template: "header"
                 "image"
                 "content"
                 / 1fr;
  align-items: center;
  gap: 30px;
  display: grid;
}

.about-us-image {
  grid-area: image;
}

.about-us-header {
  grid-area: header;
}

.about-us-content {
  grid-area: content;
}

.our-advantages {
  background-color: #0000;
  background-image: linear-gradient(#ffffffe6 0% 100%), url("../images/map-d4082270.png");
  background-position: 50% 45px;
  background-repeat: no-repeat;
  background-size: min(90%, 996px);
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.contact-card-radius {
  border-radius: 0 0 0 50px;
}

.contact-form .form-control, .contact-form .form-check-input {
  border-color: #3a32864d;
  border-radius: 0 20px;
  padding: 15px;
}

.contact-form .form-check-input {
  padding: 18px;
}

.contact-form .form-check-input:checked {
  background-color: #0000;
}

@media (width >= 576px) {
  blockquote {
    border-radius: 0 50px 0 80px;
    padding: 55px 30px 20px;
  }

  blockquote:before {
    width: 80px;
    height: 80px;
    background-size: 40px;
  }

  .header-section:before {
    display: none;
  }

  .contact-card-radius {
    border-radius: 0 100px 0 50px;
  }
}

@media (width >= 992px) {
  .date, .last-posts-date {
    height: 122px;
    width: 122px;
    flex-direction: column;
    font-size: 24px;
  }

  .contact-banner {
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 50%;
  }

  .last-posts-grid {
    grid-template: "header slider" 1fr
                   "btn slider"
                   / 34% calc(66% - 115px);
    gap: 0 40px;
  }
}

@media (width >= 1200px) {
  .map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  blockquote {
    font-size: 1.5rem;
  }

  .header-section-1 {
    position: relative;
  }

  .header-section-1:before {
    content: "";
    width: 100vw;
    z-index: 0;
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .header-section:before {
    width: 50%;
    height: 200px;
    border-bottom-left-radius: 50rem;
    display: block;
  }

  .offer-card .offer-inner {
    top: 70%;
  }

  .offer-card .offer-extended-content {
    display: block;
  }

  .offer-card:hover .offer-inner {
    top: 20px;
  }

  .offer-card:hover .offer-extended-content {
    opacity: 1;
    transition: opacity .4s .4s;
  }

  .about-us {
    grid-template-columns: 40% 1fr;
    grid-template-areas: "image header"
                         "image content";
    gap: 0 50px;
  }

  .about-us-before {
    z-index: 0;
    position: relative;
  }

  .about-us-before:before {
    content: "";
    width: 40%;
    z-index: -1;
    background-color: #fff;
    border-top-right-radius: 200px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

@media (width >= 1400px) {
  .last-posts-grid {
    grid-template-columns: 24% calc(76% - 115px);
  }

  .about-us {
    grid-template-columns: 1fr 1fr;
  }
}

@media (width <= 1849.98px) {
  :root {
    font-size: 15px;
  }
}

@media (width <= 1199.98px) {
  .map {
    width: 100%;
  }
}

@media (width <= 991.98px) {
  .date-year:before {
    content: ".";
  }
}

@media (width <= 575.98px) {
  .bg-white-sm-only {
    background-color: #fff;
  }

  #navbarNav {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  #navbarNav .header-nav {
    text-align: end;
    background-color: #0d0939;
    padding: 10px .75rem;
  }

  #navbarNav .header-nav li {
    margin: 1rem;
  }
}

/*# sourceMappingURL=custom-styles.css.map */
