.page-content ol:not(.pagination) {
  counter-reset: licznik;
  list-style: none;
  padding-left: 0;
  margin: 3rem 0;

  li {
    display: flex;
    align-items: center;
    color: $dark;
    font-weight: $font-weight-bold;
    font-size: 1rem;
    margin: 15px 0;
    padding-left: 60px;
    position: relative;
    min-height: 45px;

    &:before {
      counter-increment: licznik;
      content: counter(licznik);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;

      background-color: rgba($dark, 10%);

      border-radius: 0 20px 0 20px;
    }
  }
}
