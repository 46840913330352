.header {
  &-nav a {
    color: #fff;
    font-weight: $font-weight-bold;

    &:hover,
    &.active {
      color: $secondary;
    }
  }

  &-section-1 {
    @include media-breakpoint-up(xl) {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100vw;
        background-color: #fff;
        z-index: 0;
      }
    }
  }
}

// Phone and Mail Info

// SELECT LANG
.select-lang {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;

    background-color: $primary;
    color: $secondary;

    background-image: var(--arrow);
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
  }

  select {
    height: 60px;
    width: 60px;
    background-color: $dark;
    color: $white;
    text-align: center;

    border: 0;
    border-radius: 0 33% 0 50%;
    appearance: none;
    cursor: pointer;
  }
}

.bg-white-sm-only {
  @include media-breakpoint-down(sm) {
    background-color: #fff;
  }
}

// NAVBAR TOGGLER

.nav-toggler {
  height: 100%;
  width: 100%;
  padding: 0;
  background-color: transparent;
  border: 1px solid transparent;

  &-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 3px;
    width: 60px;
    height: 3px;
    border-radius: 15px;
    transition: background-color 0.1s;

    &:before,
    &:after {
      content: "";
      position: absolute;
      transition: transform 0.3s, width 0.3s;
      top: -15px;
      left: 0px;
      width: 40px;
      height: 4px;
      background-color: $secondary;
      transform: rotate(45deg) translate(15px, 5px);
    }

    &:after {
      top: 15px;
      transform: rotate(-45deg) translate(15px, -5px);
    }
  }

  &.collapsed &-icon {
    background-color: $secondary;

    &:before,
    &:after {
      transform: none;
      width: 60px;
      border-radius: 15px;
      height: 3px;
    }

    &:after {
      width: 40px;
    }
  }
}

// Mobile nav
#navbarNav {
  font-size: max(16px, 1rem);

  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    .header-nav {
      background-color: $primary;
      text-align: end;
      padding: 10px 0.75rem;
      li {
        margin: 1rem;
      }
    }
  }
}
