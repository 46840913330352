:root {
  --arrow: url("data-url:/svg/arrow.svg");
}

@include media-breakpoint-down(3xl) {
  :root {
    font-size: 15px;
  }
}

body {
  background-color: $light;
}

h6,
h5,
h4,
h3,
h2,
h1 {
  color: $header-color;
  font-weight: $font-weight-bolder;
}

.page-content {
  margin-bottom: 3.5rem;

  h2,
  h3 {
    margin-bottom: 0.5em;
    margin-top: 1.25em;
  }
  img{
    max-width: 100%;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.zindex-fix {
  position: relative;
  z-index: 1;
}

.img-cover {
  position: absolute;
  object-fit: cover;
  object-position: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.rounded-img {
  border-radius: 0 40px 0 80px;
  max-width: 140px;
}

.map {
  border-top-right-radius: 200px;
  min-height: 400px;

  @include media-breakpoint-down(xl) {
    width: 100%;
  }

  @include media-breakpoint-up(xl) {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
}

.hover-text-primary:hover {
  color: $primary;
}

.date {
  padding: 5px;
  width: 122px;
  background-color: $secondary;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: $font-weight-bold;
  line-height: 1.1;
  text-align: center;
  @include media-breakpoint-down(lg) {
    &-year {
      &::before {
        content: ".";
      }
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: 24px;
    flex-direction: column;
    height: 122px;
    width: 122px;
  }
}
