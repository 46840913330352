.contact-card-radius {
  border-radius: 0 0 0 50px;

  @include media-breakpoint-up(sm) {
    border-radius: 0 100px 0 50px;
  }
}

.contact-form {
  .form-control,
  .form-check-input {
    padding: 15px;
    border-color: rgba($dark, 30%);
    border-radius: 0 20px 0 20px;
  }

  .form-check-input {
    padding: 18px;
  }

  .form-check-input:checked {
    background-color: transparent;
  }
}
