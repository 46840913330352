.header {
  &-slide {
    position: relative;
    text-align: center;
    font-size: clamp(28px, 1rem + 2.5vw, 60px);
    padding: max(15%, 60px) 0.75rem;
  }
  &-img {
    filter: brightness(0.5);
    background-color: $dark;
  }

  &-section {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 75%;
      height: 75%;
      background-color: #fff;
      border-bottom-left-radius: 5rem;
    }

    @include media-breakpoint-up(sm) {
      &::before {
        display: none;
      }
    }

    @include media-breakpoint-up(xl) {
      &::before {
        display: block;
        width: 50%;
        height: 200px;
        border-bottom-left-radius: 50rem;
      }
    }
  }

  .header-slide {
    a {
      color: $white;
      transition: .5s;

      &:hover {
        color: $secondary;
      }
    }
  }
}
