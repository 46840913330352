// Bootstrap
@import "partials/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Custom
@import "partials/mixins";

// Base
@import "partials/base";

@import "partials/components/buttons";
@import "partials/components/blockquote";
@import "partials/components/ol-list";
@import "partials/components/ul-list";
@import "partials/components/table";
@import "partials/components/pagination";

// Comonents used on a few pages
@import "partials/nav";
@import "partials/components/contact-banner";
@import "partials/components/last-posts";

// Homepage styles
@import "partials/homepage/header";
@import "partials/homepage/offer";
@import "partials/homepage/about-us";
@import "partials/homepage/our-advantages";

// Contact page styles
@import "partials/contact/contact";
