.last-posts {
  position: relative;

  &-grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
      "header"
      "slider"
      "btn";

    @include media-breakpoint-up(lg) {
      gap: 0 40px;
      grid-template-columns: 34% calc(66% - 40px - 75px);
      grid-template-rows: 1fr auto;
      grid-template-areas:
        "header slider"
        "btn slider";
    }

    @include media-breakpoint-up(xxl) {
      grid-template-columns: 24% calc(76% - 40px - 75px);
    }
  }

  &-slider {
    grid-area: slider;
  }

  &-header {
    grid-area: header;
  }

  &-btn {
    grid-area: btn;
  }

  &-date {
    @extend .date;
    position: absolute;
    height: 100px !important;
    width: 100px !important;
    font-size: 20px;
    top: 0;
    left: 1.5rem;
    margin-left: 0.75rem;
  }

  &-slide {
    margin-top: 50px;
  }

  &-link {
    &::after {
      position: absolute;
      inset: 0;
      z-index: 1;
      content: "";
    }
    &:hover {
      h3 {
        text-decoration: underline;
        text-decoration-color: $secondary;
      }
    }
  }
}
