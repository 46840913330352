.about-us {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: "header" "image" "content";
  gap: 30px;
  align-items: center;

  @include media-breakpoint-up(xl) {
    grid-template-columns: 40% 1fr;
    grid-template-areas:
      "image header"
      "image content";
    gap: 0 50px;

    &-before {
      position: relative;
      z-index: 0;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 40%;

        background-color: $white;
        border-top-right-radius: 200px;
        z-index: -1;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: 1fr 1fr;
  }

  &-image {
    grid-area: image;
  }

  &-header {
    grid-area: header;
  }

  &-content {
    grid-area: content;
  }
}
