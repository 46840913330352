.contact-banner {
  border-radius: 0 50px 0 80px;
  background: linear-gradient(90deg, $secondary 0%, rgba($secondary, 50%) 100%), url("https://picsum.photos/800/450");
  background-position: 50% 50%;

  @include media-breakpoint-up(lg) {
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: 50%;
  }
}
