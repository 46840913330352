.offer {
  &-title {
    @include font-size(20px);
  }

  &-card {
    overflow: hidden;
    background-color: $dark;

    @include media-breakpoint-up(xl) {
      .offer-inner {
        top: 70%;
      }

      .offer-extended-content {
        display: block;
      }

      &:hover {
        .offer-inner {
          top: 20px;
        }

        .offer-extended-content {
          transition: opacity 0.4s ease 0.4s;
          opacity: 1;
        }
      }
    }
  }

  &-inner {
    position: absolute;
    top: auto;
    right: 20px;
    bottom: 20px;
    left: 20px;
    background-color: rgba($primary, 80%);
    z-index: 1;
    transition: top 0.6s;
  }

  &-extended-content {
    opacity: 0;
    display: none;
  }
}

.before-decoration {
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 250px;
    background-color: $primary;
    border-bottom-left-radius: 200px;
  }
}
