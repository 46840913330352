table {
  max-width: 100%;;
  td,
  th {
    border: 2px solid rgba($dark, 10%);
    padding: 15px;
    min-width: 150px;
  }

  th {
    background-color: rgba($dark, 10%);
    color: $primary;
    text-transform: uppercase;
    font-weight: $font-weight-normal;
    text-align: center;
  }

  td {
    background-color: $white;
  }
}

.table-responsive {
  margin: 3rem 0;
  overflow-x: auto;
  padding-bottom: 1rem;

  // Scrollbar Firefox
  scrollbar-color: $secondary rgba($dark, 10%);
  scrollbar-width: thin;

  // Scrollbar Chrome, Edge, and Safari
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background: $secondary;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba($dark, 10%);
    border-radius: 5px;
  }
}
