.pagination {
    @extend .list-unstyled;
    display: flex;
    justify-content: center;
    align-items: center;

    // Arrows
    &-arrow--prev,
    &-arrow--next {
        position: relative;
        border-radius: 0 20px 0 35px !important;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-image: var(--arrow);
            background-position: 50% 60%;
            background-repeat: no-repeat;
            background-size: 1.25rem;
            transform: rotate(-90deg);
        }

        &:hover::before {
            filter: brightness(0%) invert(24%) sepia(11%) saturate(4754%) hue-rotate(214deg) brightness(92%) contrast(103%);
        }
    }

    &-arrow--prev {
        transform: rotate(180deg) scaleY(-1);
        margin-right: 20px !important;
        margin-left: 0 !important;
    }

    &-arrow--next {
        margin-right: 0 !important;
        margin-left: 20px !important;
    }

    // Pages
    a {
        transition: background-color 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
        min-width: 45px;
        min-height: 45px;

        background-color: $dark;
        color: $white;
        border-radius: 0 20px 0 20px;

        &:hover {
            background-color: $secondary;
        }
    }

    .active {
        background-color: $secondary;
    }
}