blockquote {
  @include font-size(24px);
  color: $dark;
  font-weight: $font-weight-bold;
  background: linear-gradient(90deg, #d8b56d 0%, rgba(216, 181, 109, 0.5) 100%);
  border-radius: 0 20px 0 40px;
  margin: 6rem 0 3rem 0;
  padding: 50px 15px 20px 15px;
  text-align: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $white;
    background-image: url("data-url:/svg/quote.svg");
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: center;
  }

  @include media-breakpoint-up(sm) {
    padding: 55px 30px 20px 30px;
    border-radius: 0 50px 0 80px;

    &:before {
      width: 80px;
      height: 80px;
      background-size: 40px;
    }
  }
}
