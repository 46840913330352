.site-btn {
  display: inline-block;
  position: relative;
  background-color: $dark;
  border: none;
  color: $white;
  padding: 1rem 4rem;
  text-align: center;
  font-weight: $font-weight-bold;
  border-radius: 0 20px 0 40px;
  transition: background-color 0.3s;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%) rotate(-90deg);

    background-color: $primary;
    border-radius: 50%;

    background-image: var(--arrow);
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:hover,
  &:focus {
    background-color: $primary;
    color: $white;
  }
}

.site-btn-2 {
  @extend .site-btn;
  background-color: $secondary;
  color: $dark;
}
